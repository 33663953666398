import React from "react"
import { graphql } from "gatsby"

import CaseDetail from "../../components/Shared/casedetails"

const seoTitle = "Financial Reconciliation Automation (AWS Outposts + On-prem)"
const pageTitle = "Financial Reconciliation Automation"
const subTitle = "Amazon Web Services"
const para =
  "Atyeti designed a custom architecture leveraging AWS services and cutting-edge AI methodologies to automate and scale the reconciliation process."
const blockquote = `"Financial Reconciliation Automation using AWS Outposts + On-prem"`

const cardItems = [
  {
    id: 1,
    heading: `The Need`,
    listItems: (
      <>
        <p>
          {/* <Icon.Square /> */}
          A leading investment bank sought to overhaul its manual-intensive financial reconciliation pipeline. 
          With over 40,000 unique reconciliations (recons), the existing system supported only 200 due to overwhelming 
          maintenance overhead. The rules-based system, which serviced just 0.25% of recons, required four weeks 
          to onboard a single new pipeline and could not scale to address the 100 million manual touchpoints 
          per year (MTPs). The client needed an automated, scalable, and efficient solution to reduce onboarding time, 
          increase coverage, and eliminate the cost and time burden of MTPs.
        </p>
        {/* <img  src={MapImg}/> */}
      </>
    ),
  },

  {
    id: 1,
    heading: `The Approach`,
    listItems: (
      <ul>
        <li>
        AWS EC2 for rapid, on-demand compute, enabling scalable parallelization of training (onboarding) and inference tasks.
        </li>
        <li>
        AWS Outposts and S3 to seamlessly integrate on-premises data with cloud scalability.
        </li>
        <li>
        A six-transformer architecture aligned through contextual training to extract complex indicator patterns from multiple unstructured fields.
        </li>
        <li>
        A custom clustering algorithm, combining vector embeddings, a modified SNN DBSCAN variant, and graph analysis, to perform high-volume matching with low computational complexity.
        </li>
        <li>
        Close collaboration with reconciliation SMEs to encode guardrails and logical isolations, ensuring high accuracy and relevance.
        </li>
      </ul>
    ),
  },
  {
    id: 3,
    heading: `The Process:`,
    listItems: (
      <>
        <p>
          The team worked to align the transformers' contextual understanding with domain-specific needs, 
          ensuring accurate text-based indicators for matches. Custom clustering techniques provided scalable 
          processes for high-n bulk matching. Additionally, automated retraining mechanisms were designed to 
          enable continuous learning and adaptation of the system.
        </p>
      </>
    ),
  },
  {
    id: 4,
    heading: `Result`,
    listItems: (
      <ul>
        <li>
        Scalable onboarding of up to 40,000 reconciliation pipelines, 
        reducing onboarding time from four weeks to just two hours—a 99.7% improvement.
        </li>
        <li>
        Coverage per reconciliation increased by up to 250%, reducing manual touchpoints 
        and increasing operational efficiency.
        </li>
        <li>
        Inference time reduced by 99.5%, from four hours to 1.2 minutes, enabling rapid decision-making.
        </li>
        <li>
        A parallelized, continuous monitor/training architecture ensures long-term scalability and adaptability with negligible overhead.
        </li>
      </ul>
    ),
  },
]

const BlogDetails = ({ data }) => (
  <CaseDetail
    seoTitle={seoTitle}
    pageTitle={pageTitle}
    cardItems={cardItems}
    subTitle={subTitle}
    para={para}
    blockquote={blockquote}
    images={data}
  />
)
export const query = graphql`
  query FinancialReconAWSQuery {
    casedetailsJson(slug: { eq: "financial-reconciliation-automation" }) {
      images {
        image {
          childImageSharp {
            gatsbyImageData
          }
        }
      }
      slug
    }
  }
`
export default BlogDetails
